import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import {
  useNavigate,
  createSearchParams,
  useSearchParams
} from 'react-router-dom'
import moment from 'moment'
import ListFilter from '../../sections/ListFilter'
import _, { set, toInteger } from 'lodash'
import {
  Container,
  Section,
  SectionFixed,
  Loader,
  MenuButton,
  useConfirmation,
  ListTable,
  TagFilter
} from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import { fetchAnalytics, deleteAnalytic } from '../../store/Analytics/Actions'
import { toast } from 'react-toastify'
import useDidMountEffect from '../../hooks'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import EmptyRecord from '../../sections/EmptyRecords'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { checkUserRoleViewer } from '../../utils/User'
import SearchNew from '../../sections/ListFilter/SearchNew'
import Button2 from '../../components/Button/Button2'
import { CloudUpload, CrossIcon } from '../../components/Icons/Icons'
import { setUploader } from '../../store/Uploader/Actions'
import FilterListIcon from '@mui/icons-material/FilterList'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'

const analytics = (props) => {
  const { isFocused } = props
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [searchParams, setSearchParams] = useSearchParams()
  let filterParams, datefromParams, datetoParams, keywordParams
  if (isFocused) {
    filterParams = searchParams.get('filter')
    datefromParams = searchParams.get('from')
    datetoParams = searchParams.get('to')
    keywordParams = searchParams.get('keyword')
  }
  filterParams = filterParams ? JSON.parse(filterParams) : {}
  datefromParams = datefromParams ? new Date(JSON.parse(datefromParams)) : null
  datetoParams = datetoParams ? new Date(JSON.parse(datetoParams)) : null
  keywordParams = keywordParams ? JSON.parse(keywordParams) : ''

  const analyticList = useSelector(
    (state) => state.analyticList.analytics ?? []
  )
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [filterData, setFilterData] = useState({})
  const [page, setPage] = useState(localStorage.getItem('analyticsPage') || 1)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dateRange, setDateRange] = useState([datefromParams, datetoParams])
  const [selectedFilter, setSelectedFilter] = useState(filterParams)
  const [keyValueFilter, setkeyValueFilter] = useState({})
  const [searchValue, setSearchValue] = useState(keywordParams)
  const [advancedFilter, setAdvancedFilter] = useState({})
  const [selectedFilterData, setSelectedFilterData] = useState([])
  const navigate = useNavigate()
  const { PDF_VIEWER, ANALYTICS_HOME } = ROUTES
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [activeRows, setActiveRows] = useState([])
  const [selectedActiveRows, setSelectedActiveRows] = useState([])
  const [pageSize, setPageSize] = useState(15)

  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const [currentView, setCurrentView] = useState('others')

  const isUserViewer = checkUserRoleViewer()
  const [activeTableRow, setActiveTableRow] = useState(null)

  const handleonClickFile = (row) => {
    const analyticData = analyticList?.filter(
      (analytic) => analytic.id === row.fileIndex
    )[0]
    const {
      status = '',
      pdf_location = '',
      processed_pdf_location = ''
    } = analyticData

    if (status && status !== 'completed') {
      if (pdf_location) {
        setLoading(true)
        trackEvent(
          mixpanelEvents.ANALYTICS_FILE_OPENED,
          'SUCCESS',
          {},
          { message: 'Analytics in process..' }
        )
        toast.success('Document insight in progress..')
        navigate(`/analytic/pdf/${analyticData.id}/analytics-in-process`)
      } else {
        trackEvent(
          mixpanelEvents.ANALYTICS_FILE_OPENED,
          'FAILED',
          {},
          { message: 'File not found. Upload is being processed..' }
        )
        toast.error('File not found. Upload is being processed..')
      }
    } else {
      if (processed_pdf_location) {
        setLoading(true)
        trackEvent(
          mixpanelEvents.ANALYTICS_FILE_OPENED,
          'SUCCESS',
          {},
          { message: 'Analytics processed successfully..' }
        )
        navigate(`/analytic/pdf/${analyticData.id}/analytics`)
      } else {
        trackEvent(
          mixpanelEvents.ANALYTICS_FILE_OPENED,
          'FAILED',
          {},
          { message: 'File not found. Upload is being processed..' }
        )
        dispatch(toast.error('Analytics not found. Upload is being processed.'))
      }
    }
  }

  const headCells = [
    {
      id: 'documentName',
      label: 'File Name',
      accessorKey: 'documentName'
    },
    {
      id: 'uploadedBy',
      label: 'Uploaded By',
      accessorKey: 'uploadedBy'
    },
    {
      id: 'status',
      label: 'File Status',
      accessorKey: 'status'
    },
    {
      id: 'date',
      label: 'Created On',
      accessorKey: 'date'
    },
    {
      id: 'moreOptions',
      label: '',
      accessorKey: 'moreOptions',
      style: {
        width: '20px'
      }
    }
  ]

  useEffect(() => {
    if (!analyticList) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [analyticList])

  useEffect(() => {
    if (currentView === 'myFiles') {
      if (_.isArray(analyticList) && analyticList.length > 0) {
        const data = []
        let statusOptions = []
        let uploadedByOptions = []
        const uploadedByGroupOptions = []
        analyticList &&
          analyticList.forEach((analytic, indexAnalyticList) => {
            const el = {}
            el.documentName = analytic?.document_name
            el.uploadedBy = analytic?.uploaded_by
            const analyticStatus =
              analytic?.status.charAt(0).toUpperCase() +
              analytic?.status.slice(1)
            el.status = analyticStatus
            /* el.analyticsPdfLink = analytic?.processed_pdf_location ? el.documentName : "N/A" */
            const date = new Date(analytic?.created_at + 'Z')
            el.date = moment(date?.toString()).format('ll')
            el.index = indexAnalyticList
            el.id = analytic?.id
            el.moreOptions = renderTableActions(el, indexAnalyticList)
            el.fileIndex = analytic?.id
            el.uploadedByGroup =
              analytic?.uploaded_by === currentUserName ? 'Me' : 'Others'
            statusOptions.push(el.status)
            uploadedByOptions.push(analytic?.uploaded_by)
            if (analytic?.uploaded_by === currentUserName) {
              data.push(el)
            }
          })
        data?.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        setFilteredRows(data)
        setActiveRows(data.slice((page - 1) * pageSize, page * pageSize) || [])
        setRows(data)
        statusOptions = _.uniq(_.compact(statusOptions))
        uploadedByOptions = _.uniq(_.compact(uploadedByOptions))
        const filterData = {
          status: {
            id: 'status',
            label: 'Status',
            data: statusOptions
          },
          uploadedBy: {
            id: 'uploadedBy',
            label: 'Uploaded By',
            data: uploadedByOptions
          }
        }

        const newFilterData = {
          keyOptions: [
            { label: 'Status', value: 'Status' },
            { label: 'Created On', value: 'Created On' },
            { label: 'Uploaded By', value: 'Uploaded By' }
          ],
          valueOptions: {
            status: statusOptions,
            uploadedBy: uploadedByOptions
          }
        }

        setAdvancedFilter(newFilterData)
        setFilterData(filterData)
      }
    } else {
      if (_.isArray(analyticList) && analyticList.length > 0) {
        const data = []
        let statusOptions = []
        let uploadedByOptions = []
        const uploadedByGroupOptions = []
        analyticList &&
          analyticList.forEach((analytic, indexAnalyticList) => {
            const el = {}
            el.documentName = analytic?.document_name
            el.uploadedBy = analytic?.uploaded_by
            const analyticStatus =
              analytic?.status.charAt(0).toUpperCase() +
              analytic?.status.slice(1)
            el.status = analyticStatus
            const date = new Date(analytic?.created_at + 'Z')
            el.date = moment(date?.toString()).format('ll')
            el.index = indexAnalyticList
            el.id = analytic?.id
            el.moreOptions = renderTableActions(el, indexAnalyticList)
            el.fileIndex = analytic?.id
            el.uploadedByGroup =
              analytic?.uploaded_by === currentUserName ? 'Me' : 'Others'
            statusOptions.push(el.status)
            uploadedByOptions.push(analytic?.uploaded_by)
            data.push(el)
          })
        data?.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        setFilteredRows(data)
        setActiveRows(data.slice((page - 1) * pageSize, page * pageSize) || [])
        setRows(data)
        statusOptions = _.uniq(_.compact(statusOptions))
        uploadedByOptions = _.uniq(_.compact(uploadedByOptions))
        const filterData = {
          status: {
            id: 'status',
            label: 'Status',
            data: statusOptions
          },
          uploadedBy: {
            id: 'uploadedBy',
            label: 'Uploaded By',
            data: uploadedByOptions
          }
        }

        // uploadedByOptions = uploadedByOptions.map((uploadedBy) => {
        //   return {
        //     label: uploadedBy,
        //     value: uploadedBy
        //   }
        // })

        // statusOptions = statusOptions.map((status) => {
        //   return {
        //     label: status,
        //     value: status
        //   }
        // })

        // const finalFilter = {
        //   // file_type: {
        //   //   values: fileTypeOptions,
        //   //   type: 'default',
        //   //   editable: false
        //   // },

        //   created_by: {
        //     values: uploadedByOptions,
        //     type: 'default',
        //     editable: false
        //   },
        //   created_on: {
        //     type: 'multidate',
        //     editable: false
        //   }
        // }
        const newFilterData = {
          keyOptions: [
            { label: 'Status', value: 'Status' },
            { label: 'Created On', value: 'Created On' },
            { label: 'Uploaded By', value: 'Uploaded By' }
          ],
          valueOptions: {
            status: statusOptions,
            uploadedBy: uploadedByOptions
          }
        }

        setAdvancedFilter(newFilterData)
        setFilterData(filterData)
      }
    }
  }, [analyticList, currentView])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  useEffect(() => {
    filter()
  }, [rows])

  useDidMountEffect(() => {
    if (selectedFilterData.length === 0) {
      handleSearch(searchValue)
    }
  }, [selectedFilterData])

  const refresh = () => {
    dispatch(fetchAnalytics())
  }

  const handleUpload = () => {
    dispatch(
      setUploader({
        uploaderType: 'analytics',
        showUploader: true,
        fullScreen: true
      })
    )
  }

  const filter = (isReturn) => {
    const filters = selectedFilter
    const newKeyValueFilter = {}
    if (!Object.keys(filters).includes('keyvalue')) {
      setkeyValueFilter({})
    }
    let newRows = rows
    // Column Filter
    if (!_.isEmpty(filters)) {
      const filterKeys = Object.keys(filters)
      const filtered = []
      _.forEach(rows, (obj) => {
        let f = true
        filterKeys.forEach((key) => {
          if (key === 'keyvalue') {
            _.forEach(filters[key], (kv) => {
              if (!Object.keys(keyValueFilter).includes(kv)) {
                newKeyValueFilter[kv] = ''
              } else {
                newKeyValueFilter[kv] = keyValueFilter[kv]
              }
            })
          } else if (!filters[key].includes(obj[key])) {
            f = false
          }
        })
        if (f) filtered.push(obj)
      })
      newRows = filtered
    }
    // Date filter
    const from = dateRange[0]
    const to = dateRange[1]
    if (from && to) {
      newRows = _.filter(newRows, function (o) {
        return moment(o?.date).isBetween(from, to, 'day', '[]')
      })
    }
    if (!isReturn) {
      newRows = filterKeyValue(newKeyValueFilter, newRows)
      setFilteredRows(newRows)
      if (newRows.length < (page - 1) * pageSize) {
        setPage(1)
        setActiveRows(newRows.slice(0, pageSize))
      } else {
        const pageNo = toInteger(localStorage.getItem('analyticsPage')) || 1
        setPage(pageNo)
        setActiveRows(newRows.slice((pageNo - 1) * pageSize, pageNo * pageSize))
      }
    } else {
      return newRows
    }
  }

  const filterKeyValue = (currentkeyValueFilter, rows) => {
    console.log('currentkeyValueFilter', currentkeyValueFilter)
    let update = false
    if (!rows) {
      rows = filter(true)
      update = true
    }
    let newRows = rows
    if (!_.isEmpty(currentkeyValueFilter)) {
      newRows = []
      rows.forEach((row) => {
        const tags = row?.tags
        if (!_.isEmpty(tags)) {
          let flag = true
          Object.keys(currentkeyValueFilter).forEach((key) => {
            if (currentkeyValueFilter[key] === '') {
              if (!Object.keys(tags).includes(key)) {
                flag = false
              }
            } else {
              flag = tags[key]
                ?.toLowerCase()
                .includes(currentkeyValueFilter[key].toLowerCase())
            }
          })
          if (flag) {
            newRows.push(row)
          }
        }
      })
    }
    setkeyValueFilter(currentkeyValueFilter)
    if (update) {
      setPage(1)
      setFilteredRows(newRows)
      setActiveRows(newRows.slice(0, pageSize))
    }
    return newRows
  }

  const removeKeyValueFilter = (key) => {
    const newKeyValue = _.cloneDeep(keyValueFilter)
    delete newKeyValue[key]
    setkeyValueFilter(newKeyValue)
    const newSelectedFilter = _.cloneDeep(selectedFilter)
    if (newSelectedFilter.keyvalue?.includes(key)) {
      newSelectedFilter.keyvalue = _.remove(
        newSelectedFilter.keyvalue,
        function (n) {
          return n !== key
        }
      )
    }
    if (_.isEmpty(newSelectedFilter.keyvalue)) {
      delete newSelectedFilter.keyvalue
    }
    setSelectedFilter(newSelectedFilter)
  }

  const handleSearch = (key) => {
    setSearchValue(key)
    setPage(1)
    const searchKey = key.toLowerCase()
    let searchData = filteredRows
    if (searchKey === '' && selectedFilterData.length === 0) {
      setFilteredRows(rows)
      setActiveRows(rows.slice(0, pageSize))
      return
    } else if (searchKey === '' && selectedFilterData.length > 0) {
      handleFilter(selectedFilterData)
      return
    } else if (searchKey !== '' && selectedFilterData.length === 0) {
      searchData = rows
    }
    const filteredData = searchData.filter(
      (data) =>
        `${data?.client}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.createdBy}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.date}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.documentName}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.marketSector}`.toString().toLowerCase().includes(searchKey)
    )
    setFilteredRows(filteredData)
    setActiveRows(filteredData.slice(0, pageSize))
  }

  const handleFilter = (filterData = []) => {
    const newFilteredRecords = []
    setSelectedFilterData(filterData)
    rows.forEach((analytic, index) => {
      let tempRow = analytic
      filterData.forEach((data, filterIndex) => {
        let { key, value = [] } = data
        if (_.camelCase(key) === 'createdOn') {
          key = 'date'
        } else {
          key = _.camelCase(key)
        }
        if (key !== 'date') {
          if (!(value.length === 0 || value.includes(tempRow[key]))) {
            tempRow = ''
          }
        } else {
          const from = value[0]
          const to = value[1]
          if (from && to) {
            if (!moment(tempRow[key]).isBetween(from, to, 'day', '[]')) {
              tempRow = ''
            }
          }
        }
      })
      if (tempRow !== '') {
        newFilteredRecords.push(tempRow)
      }
    })
    setFilteredRows(newFilteredRecords)
    setActiveRows(newFilteredRecords.slice(0, pageSize))
  }

  const resetFilter = () => {
    setFilteredRows(rows)
    setActiveRows(rows.slice(0, pageSize))
  }

  const menuOptions = [
    {
      label: 'Remove Analytics',
      onClick: (row) => {
        const analyticData = analyticList?.filter(
          (analytic) => analytic.id === row.fileIndex
        )[0]

        if (isUserViewer) {
          if (analyticData?.uploaded_by !== currentUserName) {
            toast.error("You don't have permission to delete this file")
            return
          }
        }
        showConfirmDialog({
          onConfirm: async () => {
            // dispatch(deleteAnalytic({ id: analyticData.id }))
            dispatch(deleteAnalytic([analyticData.id]))
          },
          confirmationMessageTitle: `Are you sure you want to delete "${analyticData.document_name}" ?`
        })
      },
      disabled: isUserViewer
    },
    {
      label: 'Download File',
      onClick: (row) => {
        const analyticData = analyticList?.filter(
          (analytic) => analytic.id === row.fileIndex
        )[0]
        const analytics = analyticData ?? {}
        const originalUrl = analytics?.original_file_location
          ? analytics?.original_file_location
          : analytics.pdf_location
        const fileType = analytics?.file_extension
        const fileName = analytics.document_name.includes(`.${fileType}`)
          ? analytics.document_name
          : `${analytics.document_name}.${fileType}`
        initalizeDownload(originalUrl, fileName)
      }
    }
  ]

  const menuOpenCallback = (data) => {
    const { row, index } = data
    setActiveTableRow(index)
  }

  const renderTableActions = (row, index) => {
    const { rowLoading = false } = row
    return (
      <MenuButton
        disabled={rowLoading}
        options={menuOptions}
        callBackData={row}
        menuOpenCallback={menuOpenCallback}
        menuCloseCallback={() => setActiveTableRow(null)}
      />
    )
  }

  const handleChangeView = (view) => {
    setCurrentView(view)
  }

  const handleChangePage = (newPage) => {
    const filteredRowsClone = _.cloneDeep(filteredRows)
    const newRows = filteredRowsClone.slice(
      (newPage - 1) * pageSize,
      newPage * pageSize
    )
    localStorage.setItem('analyticsPage', newPage)
    setActiveRows(newRows)
    setPage(newPage)
  }

  const handleMultiDownload = async () => {
    const selectedRowsIds = selectedActiveRows
    const selectedRows = rows.filter((row) =>
      selectedRowsIds.includes(row.fileIndex)
    )
    const downloadLinks = selectedRows.map((row) => {
      const analyticData = analyticList?.filter(
        (analytic) => analytic.id === row.fileIndex
      )[0]
      const analytics = analyticData ?? {}
      const originalUrl = analytics?.original_file_location
        ? analytics?.original_file_location
        : analytics.pdf_location
      let fileName
      try {
        const fileType = originalUrl.split('.').pop()
        fileName = analytics.document_name.includes(`.${fileType}`)
          ? analytics.document_name
          : `${analytics.document_name}.${fileType}`
      } catch (error) {
        fileName = analytics.document_name
        console.log('error', error)
      }

      return {
        url: originalUrl,
        fileName
      }
    })

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    for (const link of downloadLinks) {
      await initalizeDownload(link.url, link.fileName)
      await delay(1000 + Math.random() * 500)
    }
  }

  const handleMultiDelete = () => {
    const selectedRowsIds = selectedActiveRows
    const selectedRows = rows.filter((row) =>
      selectedRowsIds.includes(row.fileIndex)
    )
    const selectedRowsNames = selectedRows.map((row) => row.documentName)
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(deleteAnalytic(selectedRowsIds))
      },
      confirmationMessageTitle: `Are you sure you want to delete ${selectedRowsIds.length} files?`
    })
  }

  const rowSelection = (row) => {
    const selectedRows = selectedActiveRows
    const index = selectedRows.findIndex(
      (selectedRow) => selectedRow?.index === row
    )
    if (index === -1) {
      selectedRows.push(row)
    }
    setSelectedActiveRows(selectedRows)
  }

  return loading ? (
    <Loader loading={loading} flex caption={'fetching your analytics'} />
  ) : (
    <Container>
      <SectionFixed>
        <div className="flex items-center justify-end gap-2 px-4 p-2 border-b-1">
          {/* <TagFilter
            tags={{
              ...advancedFilter
            }}
            showButton={true}
            showTags={false}
            // disabled={tableLoading}
            // selectedTags={selectedTags}
            onChange={(key, values) => {
              console.log('tags', key, values)
            }}
            clearFilter={
              false && (
                <Button2
                  secondary
                  onClick={() => {
                    // if (!tableLoading) {
                    setSelectedTags(null)
                    saveDataToLocal(null, activeCollection?.id)
                    setSearchValue('')
                    handleSearch('')
                    // }
                  }}
                >
                  <CrossIcon className="size-4 text-grey-500 " />
                  clear filter
                </Button2>
              )
            }
            filterIcon={
              <Button2 secondary>
                <FilterListIcon
                  style={{
                    height: '16px',
                    width: '16px'
                  }}
                />
                Filter
              </Button2>
            }
          /> */}

          <div className="flex items-center gap-2 mr-auto">
            <Button2
              secondary
              style={{
                padding: '4px 8px',
                fontSize: '10px',
                borderRadius: '6px',
                border:
                  currentView === 'others'
                    ? '1px solid var(--grey-400) !important'
                    : '1px solid var(--grey-200) !important',
                color:
                  currentView === 'others'
                    ? 'var(--grey-700)'
                    : 'var(--grey-600)',
                backgroundColor:
                  currentView === 'others' ? 'var(--grey-200)' : 'transparent'
              }}
              onClick={() => {
                setCurrentView('others')
              }}
            >
              All Files
            </Button2>
            <Button2
              secondary
              style={{
                padding: '4px 8px',
                fontSize: '10px',
                borderRadius: '6px',
                color:
                  currentView === 'myFiles'
                    ? 'var(--grey-700)'
                    : 'var(--grey-600)',
                border:
                  currentView === 'myFiles'
                    ? '1px solid var(--grey-400) !important'
                    : '1px solid var(--grey-200) !important',
                backgroundColor:
                  currentView === 'myFiles' ? 'var(--grey-200)' : 'transparent'
              }}
              onClick={() => {
                setCurrentView('myFiles')
              }}
            >
              My Uploads
            </Button2>
          </div>

          {selectedActiveRows.length > 0 ? (
            <>
              <Button2
                secondary
                onClick={() => handleMultiDelete()}
                disabled={isUserViewer}
              >
                <DeleteIcon className="size-4" />
                Delete
              </Button2>
              <Button2 secondary onClick={() => handleMultiDownload()}>
                <DownloadIcon className="size-4" />
                Download
              </Button2>
            </>
          ) : (
            <Button2 secondary onClick={() => refresh()}>
              <RefreshIcon className="size-4" />
            </Button2>
          )}

          <ListFilter
            // refresh={refresh}
            filterData={filterData}
            handleSearch={handleSearch}
            filter={filter}
            searchValue={searchValue}
            searchOnEnter={() => {}}
            keyValueFilter={keyValueFilter}
            filterKeyValue={filterKeyValue}
            removeKeyValueFilter={removeKeyValueFilter}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
            pageType="analytics"
            advancedFilter={advancedFilter}
            handleFilter={handleFilter}
            resetFilter={resetFilter}
            handleChangeView={handleChangeView}
            currentView={currentView}
            isEditable={false}
            hideSearch={true}
          />

          <SearchNew
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            onEnter={(value) => {
              handleSearch(value)
            }}
            onClear={() => {
              setSearchValue('')
              handleSearch('')
            }}
            style={{
              padding: '6px 10px'
            }}
          />

          <Button2
            primary
            onClick={handleUpload}
            style={{
              padding: '6px 10px'
            }}
          >
            <CloudUpload className="size-4" />
            Upload
          </Button2>
        </div>
      </SectionFixed>
      <Section overFlow>
        <div className={tableContainer}>
          <HeadlessTable
            leftAlign={true}
            columns={headCells}
            data={activeRows}
            // meta={meta}
            meta={{
              pageSize,
              totalCount: filteredRows?.length,
              page
            }}
            loading={loading}
            selectedData={selectedActiveRows}
            handleSelectedData={({ id }) => {
              if (selectedActiveRows.includes(id)) {
                setSelectedActiveRows(
                  selectedActiveRows.filter((item) => item !== id)
                )
              } else {
                setSelectedActiveRows([...selectedActiveRows, id])
              }
            }}
            pageLoading={false}
            onRowClick={handleonClickFile}
            fetchPage={(page) => {
              handleChangePage(page)
            }}
            showOptions={true}
          />
        </div>
      </Section>
      {ConfirmDialog}
    </Container>
  )
}

export default analytics
