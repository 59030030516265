import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import { ImageAvatarFallback, SelectDropDown } from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useDispatch } from 'react-redux'
import { mergePeople } from '../../store/People/Actions'
import { toast } from 'aws-amplify'

const MergeModal = ({
  records,
  isOpen,
  selectedPeopleList,
  onMerge,
  title,
  buttonText,
  buttonFunction,
  update,
  create,
  onClose,
  type = 'create' || 'edit',
  collectionInfo,
  s3Obj
}) => {
  const [collection, setCollection] = useState(null)
  const [isPrivate, setIsPrivate] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [loading, setLoading] = useState(false)
  const [finalPerson, setFinalPerson] = useState(null)
  const [selectedPeople, setSelectedPeople] = useState(selectedPeopleList)
  const [profileImages, setProfileImages] = useState([])
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('')
  const [groupIds, setGroupIds] = useState(
    selectedPeopleList.map((person) => person.id)
  )

  const dispatch = useDispatch()

  const [uploadedImages, setUploadedImages] = useState([])
  useEffect(() => {
    initMerge()
  }, [])

  console.log('finalPerson', finalPerson, selectedPeopleList, groupIds)

  const initMerge = async () => {
    let finalTags = []
    let finalEmails = []
    let employee_type = ''
    const finalPersonTemp = {
      ...selectedPeople[0],
      properties: {
        email_ids: {
          value: []
        },
        employee_type: {
          value: ''
        }
      }
    }

    const profileTemp = []
    let resumes = []
    selectedPeople.map((person) => {
      finalTags = [...finalTags, ...person.tags]
      resumes = [...resumes, ...person.resumes]

      if (
        person?.properties?.email_ids &&
        person?.properties?.email_ids?.value?.length > 0
      ) {
        finalEmails = [...finalEmails, ...person?.properties?.email_ids?.value]
      }

      if (
        person?.properties?.employee_type &&
        person?.properties?.employee_type?.value
      ) {
        employee_type = person?.properties?.employee_type?.value
      }
      if (person.profile_picture.length > 0) {
        profileTemp.push(person.profile_picture)
      }
      return null
    })

    setSelectedProfile(profileTemp[0])
    setProfileImages(profileTemp)

    setFinalPerson({
      ...finalPersonTemp,
      profile_picture: profileTemp.length > 0 ? profileTemp[0] : '',
      properties: {
        email_ids: {
          value: finalEmails,
          verified: finalEmails.length > 0
        },
        employee_type: {
          value: employee_type,
          verified: employee_type !== ''
        }
      },
      resumes,
      tags: finalTags
    })
  }

  const handleMergePeople = async () => {
    setLoading(true)

    let body = {
      group_ids: groupIds,
      employee_name: finalPerson?.name,
      properties: finalPerson?.properties
    }

    if (uploadedImages.includes(selectedProfile)) {
      body = {
        ...body,
        profile_picture: {
          src: selectedProfile,
          name: 'uploadedImg'
        }
      }
    } else {
      body = {
        ...body,
        profile_picture: {
          url: selectedProfile
        }
      }
    }

    console.log('mergePeople', body)

    // const id = toast.loading('Please wait...')
    trackEvent(mixpanelEvents.PEOPLE_RESUME_MERGED, 'SUCCESS', {}, {})
    // setIsMerge('loading')

    const callback = () => {
      // setIsMerge(false)
      // setSelectedPeople([])
      // toast.dismiss(id)
      setLoading(false)

      onMerge()
    }

    const fallback = () => {
      // setIsMerge(true)
      // toast.dismiss(id)
      toast.error('Failed to merge people')
      setLoading(false)
    }
    dispatch(mergePeople(body, callback, fallback))
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          maxHeight: '450px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div
        className="flex flex-col justify-between h-full relative"
        style={{
          width: '700px',
          height: '450px',
          overflow: 'auto'
        }}
      >
        <div className="grid grid-col-2">
          <div>
            <p className="m-0 text-sm px-2 text-center font-medium">
              Selected People
            </p>
            <div className="p-3">
              {selectedPeople.map((person, index) => {
                return (
                  <div className="flex items-center gap-2 mt-3" key={index}>
                    <div className="flex items-center gap-2">
                      <ImageAvatarFallback
                        profilePic={person?.profile_picture}
                        s3Obj={s3Obj}
                        style={{
                          width: '35px',
                          height: '35px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                          border: '1px solid var(--grey-200)'
                        }}
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <p className="m-0 font-medium text-xs">
                          {person?.name}
                        </p>
                        {person?.properties?.employee_type?.value && (
                          <p className="m-0 bg-grey-100 rounded-full text-xxs px-1">
                            {person?.properties?.employee_type?.value}
                          </p>
                        )}
                      </div>
                      <p className="m-0 text-xxs">
                        {person.resumes.length} resumes
                      </p>
                    </div>
                    {selectedPeople.length > 2 && (
                      <Button2
                        secondary
                        // noOutline
                        onClick={() => {
                          const finalPeople = selectedPeople.filter(
                            (item) => item.id !== person.id
                          )
                          setSelectedPeople(finalPeople)

                          setGroupIds(
                            groupIds.filter((item) => item !== person.id)
                          )

                          initMerge()
                        }}
                        style={{
                          marginLeft: 'auto',
                          padding: '2px 6px',
                          fontSize: '10px'
                        }}
                      >
                        Deselect
                      </Button2>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          {finalPerson && (
            <div
              style={{
                borderLeft: '1px solid var(--grey-200)'
              }}
            >
              <p className="m-0 text-sm px-2 text-center font-medium">Review</p>

              <div className="p-3">
                <div className="flex flex-col justify-center  w-full">
                  {editProfileImage ? (
                    <div className="w-full">
                      <div className="flex gap-2 mt-2 items-center">
                        {selectedProfile && (
                          <ImageAvatarFallback
                            profilePic={selectedProfile}
                            key={selectedProfile}
                            s3Obj={s3Obj}
                            style={{
                              width: '65px',
                              height: '65px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                              border: '1px solid var(--grey-200)'
                            }}
                          />
                        )}{' '}
                        {profileImages.map((profile, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                // setFinalPerson({
                                //   ...finalPerson,
                                //   profile_picture: profile
                                // })

                                setSelectedProfile(profile)
                              }}
                            >
                              <ImageAvatarFallback
                                profilePic={profile}
                                s3Obj={s3Obj}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                  border:
                                    selectedProfile === profile
                                      ? '1.5px solid var(--grey-800)'
                                      : '1px solid var(--grey-100)'
                                }}
                              />
                            </div>
                          )
                        })}
                        {uploadedImages.map((profile, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                // setFinalPerson({
                                //   ...finalPerson,
                                //   profile_picture: profile
                                // })

                                setSelectedProfile(profile)
                              }}
                            >
                              <ImageAvatarFallback
                                profilePic={profile}
                                s3Obj={s3Obj}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                  border:
                                    selectedProfile === profile
                                      ? '1px solid var(--grey-800)'
                                      : '1px solid var(--grey-100)'
                                }}
                              />
                            </div>
                          )
                        })}
                        <label
                          htmlFor="profileUpload"
                          className="text-white grid place-content-center bg-grey-400 cursor-pointer"
                          style={{
                            height: '35px',
                            width: '35px',
                            borderRadius: '50px'
                          }}
                        >
                          +
                          <input
                            id="profileUpload"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              const file = e.target.files?.[0]
                              if (file) {
                                const reader = new FileReader()
                                reader.onloadend = () => {
                                  const base64String = reader.result
                                    ?.toString()
                                    .split(',')[1]
                                  if (base64String) {
                                    const prefix = `data:${file.type};base64,`
                                    const src = prefix + base64String

                                    if (src) {
                                      console.log('base64 src', src)
                                      setUploadedImages([
                                        ...uploadedImages,
                                        src
                                      ])

                                      setSelectedProfile(src)
                                    }
                                  }
                                }
                                reader.readAsDataURL(file)
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <Button2
                          secondary
                          noOutline
                          onClick={() => {
                            setEditProfileImage(false)
                            setSelectedProfile(finalPerson?.profile_picture)
                          }}
                          style={{
                            padding: '4px 7px',
                            fontSize: '10px'
                          }}
                        >
                          Cancel
                        </Button2>
                        <Button2
                          primary
                          style={{
                            padding: '4px 7px',
                            fontSize: '10px'
                          }}
                          onClick={() => {
                            setFinalPerson({
                              ...finalPerson,
                              profile_picture: selectedProfile
                            })

                            setEditProfileImage(false)
                          }}
                        >
                          Change
                        </Button2>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      {finalPerson?.profile_picture && (
                        <div
                          className="relative"
                          onClick={() => {
                            setEditProfileImage(true)
                          }}
                        >
                          <ImageAvatarFallback
                            profilePic={finalPerson?.profile_picture}
                            s3Obj={s3Obj}
                            style={{
                              width: '65px',
                              height: '65px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                              border: '1px solid var(--grey-200)'
                            }}
                          />
                          <span className="absolute bottom-0 right-0 bg-white rounded-full p-1 border-grey-200 grid place-content-center">
                            <PencilIcon className="size-3" />
                          </span>
                        </div>
                      )}

                      <div className="w-full">
                        <input
                          className="input-base border-grey-200 p-1 rounded-md w-full text-sm mb-1"
                          value={finalPerson?.name}
                          onChange={(e) => {
                            setFinalPerson({
                              ...finalPerson,
                              name: e.target.value
                            })
                          }}
                        />
                        {/* <p className="m-0">{finalPerson?.name}</p> */}

                        <p className="m-0 text-xs ml-2">
                          {finalPerson.resumes.length} Resumes
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="text-left mt-4 w-full">
                    <span className="text-xs text-grey-600 ml-1">
                      Employee type
                    </span>
                    <div className="mt-1">
                      <SelectDropDown
                        name="employeeType"
                        label=""
                        value={finalPerson?.properties?.employee_type?.value}
                        options={[
                          { label: 'Internal', value: 'internal' },
                          { label: 'External', value: 'external' },
                          { label: 'Unknown', value: 'unknown' }
                        ]}
                        handleChange={(e) => {
                          console.log('select value', e)
                          setFinalPerson({
                            ...finalPerson,
                            properties: {
                              ...finalPerson.properties,
                              employee_type: {
                                ...finalPerson.properties.employee_type,
                                value: e
                              }
                            }
                          })
                        }}
                      />
                    </div>
                  </div>

                  <div className="text-left mt-4 w-full">
                    <div className="flex gap-1 items-center mb-1">
                      <span className="text-xs text-grey-600 ml-1">Emails</span>

                      <Button2
                        secondary
                        noOutline={true}
                        onClick={() => {
                          setFinalPerson({
                            ...finalPerson,
                            properties: {
                              ...finalPerson.properties,
                              email_ids: {
                                ...finalPerson.properties.email_ids,
                                value: [
                                  ...finalPerson?.properties?.email_ids?.value,
                                  ''
                                ]
                              }
                            }
                          })
                        }}
                        style={{
                          // marginTop: '4px',
                          padding: '3px'
                        }}
                      >
                        {' '}
                        <PlusIcon className="size-4" />
                      </Button2>
                    </div>

                    {finalPerson?.properties?.email_ids?.value.length <= 1 ? (
                      <input
                        type="text"
                        className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded-md text-sm border-box"
                        style={{}}
                        value={finalPerson?.properties?.email_ids?.value[0]}
                        placeholder="john@example.org"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={(e) => {
                          e.stopPropagation()
                          setFinalPerson({
                            ...finalPerson,
                            properties: {
                              ...finalPerson.properties,
                              email_ids: {
                                value: [e.target.value],
                                ...finalPerson?.properties?.email_ids
                              }
                            }
                          })
                        }}
                        onBlur={(e) => {
                          e.stopPropagation()
                        }}
                      />
                    ) : (
                      <div className="flex flex-col gap-2 w-full">
                        {finalPerson?.properties?.email_ids?.value?.map(
                          (email, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center gap-1"
                              >
                                <input
                                  type="text"
                                  className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded-md text-sm border-box"
                                  style={{}}
                                  autoFocus
                                  value={email}
                                  placeholder="Enter email"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation()
                                    console.log(
                                      'onCange',
                                      finalPerson?.properties?.email_ids?.value.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return e.target.value
                                          }
                                          return item
                                        }
                                      )
                                    )
                                    setFinalPerson({
                                      ...finalPerson,
                                      properties: {
                                        ...finalPerson?.properties,
                                        email_ids: {
                                          ...finalPerson?.properties.email_ids,
                                          value:
                                            finalPerson?.properties?.email_ids?.value.map(
                                              (item, i) => {
                                                if (i === index) {
                                                  return e.target.value
                                                }
                                                return item
                                              }
                                            )
                                        }
                                      }
                                    })
                                  }}
                                  onBlur={(e) => {
                                    e.stopPropagation()
                                  }}
                                />
                                <Button2
                                  secondary
                                  noOutline
                                  onClick={() => {
                                    setFinalPerson({
                                      ...finalPerson,
                                      properties: {
                                        ...finalPerson?.properties,
                                        email_ids: {
                                          ...finalPerson?.properties?.email_ids
                                            .email_ids,
                                          value:
                                            finalPerson?.properties?.email_ids?.value.filter(
                                              (item, i) => i !== index
                                            )
                                        }
                                      }
                                    })
                                  }}
                                  style={{
                                    padding: '6px 8px'
                                  }}
                                >
                                  <CrossIcon className="size-4" />
                                </Button2>
                              </div>
                            )
                          }
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 mt-3 ml-1 bg-white w-full">
        <div className="flex gap-1 ml-auto">
          <Button2 variant="outlined" onClick={onClose} secondary noOutline>
            Cancel
          </Button2>
          <Button2
            variant="outlined"
            onClick={handleMergePeople}
            primary
            // disabled={collectionName === ''}
          >
            {loading ? (
              <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
            ) : (
              'Merge'
            )}
          </Button2>
        </div>
      </div>{' '}
    </Dialog>
  )
}

export default MergeModal

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'var(--grey-800)',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc'
        })
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)'
    })
  }
}))
