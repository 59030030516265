import { useEffect, useState } from 'react'
import { initalizeS3 } from '../../utils/AWS'
import { useStyles } from './styles'
import { ImageGallery, TagFilter, Button } from '../../components'
import {
  fetchPeople,
  mergePeople,
  updatePeopleTag
} from '../../store/People/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box, Drawer, CircularProgress } from '@material-ui/core'
import Loader from '../../components/Loader'
import { Container, Section, SectionFixed } from '../../components/Container'
import _ from 'lodash'
import { useResizeDetector } from 'react-resize-detector'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import { multiDocuments } from '../../store/api'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import { toast } from 'react-toastify'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import ChecklistIcon from '@mui/icons-material/Checklist'
import StyleIcon from '@mui/icons-material/Style'
import { ROUTES } from '../../config/routes'
import { useLocation, useParams } from 'react-router-dom'
import { checkUserRoleViewer } from '../../utils/User'
import PeopleTable from './PeopleTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import Button2 from '../../components/Button/Button2'
import { CrossIcon, MergeIcon } from '../../components/Icons/Icons'
import { updatePeopleInfo } from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import Label from '@mui/icons-material/Label'
import AlphabetSearch from './AlphabetSearch'
import MergeModal from './MergeModal'

const PeopleResume = ({ isSuperUser = false, showSearch = false }) => {
  const [s3Obj, sets3Obj] = useState({})
  const [selectedScreenshots, setSelectedScreenshots] = useState({})
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(null)
  const [pageLoading, setPageLoading] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { width, height, ref } = useResizeDetector()
  const [isResumeView, setIsResumeView] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [isMerge, setIsMerge] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [selectedPeopleObj, setSelectedPeopleObj] = useState([])
  const [notesList, setNotesList] = useState([])
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [tagState, setTagState] = useState({})
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [prevFilters, setPrevFilters] = useState({})
  const [searchOnKey, setSearchOnKey] = useState(false)
  const [resumeOpen, setResumeOpen] = useState(false)
  const [meta, setMeta] = useState({})
  const [currentMasterPeopleIndex, setCurrentMasterPeopleIndex] = useState('')
  const [currentResumeIndex, setCurrentResumeIndex] = useState('')
  const [isIndividualResume, setIsIndividualResume] = useState(false)
  const { masterResumeId = '', resumeId = '' } = useParams()
  const [alphabetKey, setAlphabetKey] = useState(null)
  const location = useLocation()
  const { LIBRARY_PEOPLE_RESUME } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_PEOPLE_RESUME)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    const savedPage = localStorage.getItem('peoplePage')
    console.log('savedPAge', savedPage)
    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      console.log('page updated', savedPage)

      localStorage.setItem('peoplePage', 1)
      setPage(1)
    }
  }, [])

  useEffect(() => {
    if (page) {
      fetchData(page)
    }

    localStorage.setItem('peoplePage', page)
  }, [page])

  useEffect(() => {
    if (page === 1) {
      fetchData(page)
    } else {
      setPage(1)
    }
  }, [alphabetKey])

  useEffect(() => {
    const tagsValues = {}
    const tagMap = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        key_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (key_type?.includes('people')) {
        data.forEach((element) => {
          tagMap[element?.id] = {
            key,
            ...element
          }
          if (element.value) {
            if (tagsValues[key]?.values) {
              tagsValues[key].values.push({
                value: element.id,
                label: element.value
              })
            } else {
              if (!tagsValues[key]) tagsValues[key] = {}
              tagsValues[key].values = [
                {
                  value: element.id,
                  label: element.value
                }
              ]
            }
          }
          tagsValues[key] = {
            ...tagsValues[key],
            type,
            ...rest
          }
        })
      }
    })
    setTagsOptions(tagsValues)
    setTagState(tagMap)
  }, [tagsCenterStateTags])

  useEffect(() => {
    if (!width || !height || pageSize) return
    const size = getPageCount(width, height)
    // setPageSize(size)
  }, [width, height])

  useEffect(() => {
    console.log('selectedPeopleObj', selectedPeopleObj)
  }, [selectedPeopleObj])

  const peopleList = useSelector((state) => state.people?.peopleList)
  const [peopleData, setPeopleData] = useState([])
  const peopleFilters = useSelector((state) => state.people?.filters)

  const hasMoreDataState = useSelector((state) => state.people?.hasMoreData)

  const hasMoreData = isResumeView ? false : hasMoreDataState

  useEffect(() => {
    if (isFocused && showSearch) {
      if (!_.isEmpty(peopleFilters)) {
        const {
          searchValue: prevSearchValue,
          selectedTags: prevSelectedTags,
          searchOnKey: prevSearchOnKey
        } = peopleFilters
        if (prevSearchValue !== searchValue) {
          setSearchValue(prevSearchValue)
        }
        if (!_.isEqual(prevSelectedTags, selectedTags)) {
          setSelectedTags(prevSelectedTags)
        }
        if (prevSearchOnKey !== searchOnKey) {
          setSearchOnKey(prevSearchOnKey)
        }
      }
    }
  }, [peopleFilters, isFocused, showSearch])

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => {
          setShowTagDrawer(false)
          setSelectedPeople([])
        }}
      >
        <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
          {selectedPeople.length === 1 ? (
            <TagCenterUpdate
              id={selectedPeople?.[0]}
              filterTags="people"
              document_type={'masterresume'}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedPeople([])
              }}
            />
          ) : (
            <TagCenterUpdate
              // id={selectedPeople?.[0]}
              isOnlySelect={true}
              filterTags="people"
              // document_type="masterresume"
              selectCallback={(e) => handleMultiTags(e)}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedPeople([])
              }}
            />
          )}
        </Box>
      </Drawer>
    )
  }

  const handleMultiTags = async (totalTags) => {
    if (selectedPeople.length === 0) {
      toast.error('Please select atleast one person')
      return
    }
    const datetags = {}
    const tags = {}
    const dateArr = {}
    const tagsArr = {}
    for (const key in totalTags) {
      if (
        ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
      ) {
        datetags[key] = totalTags[key]
      } else {
        tags[key] = totalTags[key]
      }
    }
    Object.keys(datetags).forEach((key) => {
      datetags[key].forEach((item) => {
        if (dateArr[key]) {
          dateArr[key].push({
            tag_key: item.key,
            tag_value: item.label,
            tag_type: item.tag_type
          })
        } else {
          dateArr[key] = [
            {
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            }
          ]
        }
      })
    })
    Object.keys(tags).forEach((key) => {
      tags[key].forEach((item) => {
        if (tagsArr[key]) {
          tagsArr[key].push({
            tag_key: item.key,
            tag_value: item.value,
            tag_label: item.label
          })
        } else {
          tagsArr[key] = [
            {
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            }
          ]
        }
      })
    })
    for (const key in dateArr) {
      if (dateArr[key].length === 0) {
        delete dateArr[key]
      }
    }
    for (const key in tagsArr) {
      if (tagsArr[key].length === 0) {
        delete tagsArr[key]
      }
    }
    if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
      toast.error('Please select atleast one tag')
    } else {
      setShowTagDrawer(false)
      setIsMerge('loadingTag')
      const toastId = toast.info(
        <div style={{ display: 'flex' }}>
          {'Tagging Selected People'}&nbsp;
          <CircularProgress size={20} />
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          draggable: false
        }
      )
      const documents = selectedPeople.map((item) => {
        return {
          id: item,
          file_type: 'masterresume'
        }
      })
      const req = {
        documents,
        action: 'tags',
        data: { tags: tagsArr, date_tags: dateArr }
      }
      const res = await multiDocuments(req)
      toast.dismiss(toastId)
      if (res.status === 200) {
        const { new_tags } = res.data
        const newTagPayload = {}
        new_tags.forEach((item) => {
          if (!newTagPayload[item.key]) {
            newTagPayload[item.key] = []
          }
          newTagPayload[item.key].push(item)
        })
        Object.keys(newTagPayload || {}).forEach((key) => {
          dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
        })
        const tagsFormated = {}
        const keysToRemove = []
        Object.keys(tagsArr || {}).forEach((key) => {
          keysToRemove.push(key)
          tagsArr[key].forEach((item) => {
            if (tagsFormated[key]) {
              tagsFormated[key].push({
                tag_key: key,
                tag_value: item.tag_label,
                id: item.tag_value
              })
            } else {
              tagsFormated[key] = [
                {
                  tag_key: key,
                  tag_value: item.tag_label,
                  id: item.tag_value
                }
              ]
            }
          })
        })
        Object.keys(datetags || {}).forEach((key) => {
          const values = datetags[key]
          keysToRemove.push(key)
          const { data = [] } = tagsCenterStateTags[key] || {}
          values.forEach((item) => {
            const tag = data.find((element) => {
              return element.value === item.label
            })
            if (tag) {
              if (tagsFormated[key]) {
                tagsFormated[key].push({
                  tag_key: key,
                  tag_value: tag.value,
                  id: tag.id
                })
              } else {
                tagsFormated[key] = [
                  {
                    tag_key: key,
                    tag_value: tag.value,
                    id: tag.id
                  }
                ]
              }
            } else {
              const newTag = new_tags.find((element) => {
                return element.value === item.label
              })
              if (newTag) {
                if (tagsFormated[key]) {
                  tagsFormated[key].push({
                    tag_key: key,
                    tag_value: newTag.value,
                    id: newTag.id
                  })
                } else {
                  tagsFormated[key] = [
                    {
                      tag_key: key,
                      tag_value: newTag.value,
                      id: newTag.id
                    }
                  ]
                }
              }
            }
          })
        })
        selectedPeople.map((id) => {
          const index = peopleList.findIndex((item) => item.id === id)
          if (index === -1) return null
          const oldTags = peopleList[index].tags
          const tagsDict = {}
          oldTags?.forEach((id) => {
            const item = tagState[id]
            if (tagsDict[item.key]) {
              tagsDict[item.key].push(id)
            } else {
              tagsDict[item.key] = [id]
            }
          })
          Object.keys(tagsFormated).forEach((key) => {
            tagsDict[key] = tagsFormated[key].map((item) => item.id)
          })
          const newTags = []
          Object.keys(tagsDict).forEach((key) => {
            newTags.push(...tagsDict[key])
          })
          const payload = {
            type: 'masterresume',
            id: [id],
            tags: newTags
          }

          console.log('payload tags', payload)
          dispatch(updatePeopleTag(payload))
          return null
        })
        setIsMerge(false)
        setSelectedPeople([])
      } else {
        toast.error('Error in tagging. Try again later')
        setIsMerge(true)
      }
    }
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      fetchData(page)
    }
  }

  const handleMerge = () => {
    if (selectedPeople.length < 2) {
      toast.error('Please select atleast two person')
      return
    }

    setShowMergeModal(true)
    // return
  }

  const extraButtons = [
    !isUserViewer && {
      text: `${
        isMerge === 'loading'
          ? 'Merging...'
          : isMerge === true
          ? 'Merge Selected People'
          : 'Merge'
      } `,
      condition: true,
      onClick: handleMerge,
      icon: '',
      tooltip: `${
        isMerge === 'loading'
          ? 'Merging...'
          : isMerge === true
          ? 'Merge Selected People'
          : 'Merge'
      } `
    },
    {
      text: 'cancel',
      condition: isMerge === true,
      onClick: () => {
        setIsMerge(false)
        setSelectedPeople([])
        setShowTagDrawer(false)
      },
      icon: '',
      tooltip: 'cancel'
    }
  ]

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  const refresh = (callback = () => {}) => {
    setIsMerge(false)
    setSelectedPeople([])
    setLoading(true)
    // setPage(meta?.page ?? 1)
    setSearchValue('')
    setSelectedTags(null)
    setSearchOnKey(false)
    fetchData(meta?.page ?? 1, '', null)
    callback()
  }

  const isLastRowVisible = () => {
    const rows = document.getElementById('rows')
    if (rows) {
      const lastRow = rows.lastElementChild
      const lastRowRect = lastRow.getBoundingClientRect()
      return lastRowRect.bottom <= window.innerHeight
    }
    return false
  }

  const infiniteScroll = () => {
    if (isLastRowVisible() && hasMoreData && pageSize >= page && !pageLoading) {
      fetchMoreData()
    }
  }

  useEffect(() => {
    let timeout = ''
    if (
      !peopleList &&
      peopleList !== 'loading' &&
      isFocused &&
      pageSize &&
      showSearch
    ) {
      setLoading(true)
      fetchData(page)
    } else if (peopleList === 'loading') {
      setLoading(true)
    } else {
      const notes = peopleList?.map((e) => {
        return { id: e.id, notes: e.notes }
      })
      setNotesList(notes)
      setLoading(false)
      const hasScroll = checkHorizontalScrollbar('scrollableDiv')
      if (masterResumeId) {
        if (!peopleList && peopleList !== 'loading') {
          fetchSingleResume()
        } else {
          const index = peopleList?.findIndex(
            (item) => item?.id === masterResumeId
          )
          if (index !== -1) {
            setCurrentMasterPeopleIndex(index)
            if (resumeId) {
              const resumeIndex = peopleList[index]?.resumes?.findIndex(
                (item) => item?.id === resumeId
              )
              if (resumeIndex !== -1) {
                setCurrentResumeIndex(resumeIndex)
              }
            }
          } else {
            fetchSingleResume()
          }
        }
      }
      timeout = setTimeout(() => {
        if (isLastRowVisible() && hasMoreData && pageSize >= page) {
          fetchMoreData()
        }
      }, 500)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [peopleList, isFocused, pageSize, masterResumeId, resumeId])

  const fetchSingleResume = () => {
    if (currentMasterPeopleIndex === '') {
      if (isIndividualResume) {
        setCurrentMasterPeopleIndex(0)
      } else {
        setLoading(true)
        const newFilters = {}
        setPrevFilters(newFilters)
        const req = {
          keyword: '',
          page_num: 1,
          page_size: 10,
          tags: [],
          tag_dates: [],
          master_people_id: masterResumeId,
          isSingleResume: true
        }
        const callback = (payload) => {
          const { peopleList } = payload

          setIsIndividualResume(peopleList)
          setCurrentMasterPeopleIndex(0)
          if (resumeId) {
            const resumeIndex = peopleList[0]?.resumes?.findIndex(
              (item) => item?.id === resumeId
            )
            if (resumeIndex !== -1) {
              setCurrentResumeIndex(resumeIndex)
            }
          } else {
            const people = peopleList[0]
            if (people?.resumes?.length > 0) {
              window.location.href = `/library/people-resume/${masterResumeId}/${people?.resumes[0]?.id}`
            }
          }
          setLoading(false)
        }
        dispatch(fetchPeople(req, newFilters, callback))
      }
    } else {
      if (resumeId) {
        const resumeIndex = isIndividualResume[
          currentMasterPeopleIndex
        ]?.resumes?.findIndex((item) => item?.id === resumeId)
        if (resumeIndex !== -1) {
          setCurrentResumeIndex(resumeIndex)
        }
      }
    }
  }
  const fetchData = (
    page,
    searchVal = searchValue,
    selectedTagsObj = selectedTags
  ) => {
    console.log('fetchData', pageSize, page)
    if (pageSize && page) {
      const tag_values = []
      const tag_dates = []
      selectedTagsObj &&
        Object.keys(selectedTagsObj).forEach((key) => {
          const tags = []
          let { condition, values = [] } = selectedTagsObj[key] || {}
          values = values || []
          values.forEach((item) => {
            if (item?.type?.includes('date')) {
              tag_dates.push({ ...item, condition })
            } else {
              tags.push(item.value)
            }
          })
          if (tags.length > 0) {
            tag_values.push({
              key,
              condition,
              values: tags
            })
          }
        })
      setLoading(true)
      const newFilters = {
        searchValue: searchVal,
        selectedTags: selectedTagsObj,
        searchOnKey: !!searchVal
      }
      setPrevFilters(newFilters)
      const req = {
        keyword: searchVal,
        alphabetical: alphabetKey,
        page_num: page,
        page_size: pageSize,
        tags: tag_values,
        tag_dates
      }
      dispatch(
        fetchPeople(req, newFilters, (payload) => {
          console.log('people data', payload)
          setMeta({
            page: payload?.currentPage,
            pageSize: payload?.pageSize,
            totalCount: payload?.totalCount
          })
          setPeopleData(payload?.peopleList)
          setLoading(false)
        })
      )
    }
  }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleMasterResumeNameUpdate = (editingNameId, editedName) => {
    const id = toast.loading('Changing name...')
    const data = {
      id: editingNameId,
      employee_name: editedName
    }
    const callback = () => {
      refresh(() => {
        toast.dismiss(id)
      })
    }
    trackEvent(
      mixpanelEvents.PEOPLE_MASTER_RESUME_NAME_UPDATED,
      'Success',
      {},
      { people_name: editedName }
    )
    dispatch(updatePeopleInfo(data, callback))
  }

  const fetchMoreData = () => {
    const nextPage = page + 1
    // setPage(nextPage)
    // setPageLoading(true)
  }

  const handleSearchQuery = (newSelectedTags = selectedTags) => {
    setLoading(true)
    const newFilters = {
      searchValue,
      selectedTags: newSelectedTags
    }
    console.log('called')
    if (_.isEqual(newFilters, prevFilters)) {
      console.log('same filters')
      setLoading(false)
    } else {
      setPage(1)
      fetchData(1)
    }
  }

  useEffect(() => {
    if (isFocused && selectedTags) {
      handleSearchQuery(selectedTags)
    }
  }, [selectedTags, isFocused])

  const handleSearchEnter = (event) => {
    if (event?.keyCode === 13) {
      const newFilters = {
        searchValue,
        selectedTags
      }
      if (_.isEqual(newFilters, prevFilters)) {
        console.log('same filters')
        setLoading(false)
      } else {
        trackEvent(
          mixpanelEvents.PEOPLE_RESUME_SEARCHED,
          'SUCCESS',
          {},
          { search_value: searchValue }
        )
        setLoading(true)
        setPage(1)
        fetchData(1)
        if (searchValue) {
          setSearchOnKey(true)
        } else {
          setSearchOnKey(false)
        }
      }
    }
  }

  const getPageCount = (containerWidth, containerHeight) => {
    const elementWidth = 322
    const elementHeight = 142
    const gap = 15
    const padding = 20
    const effectiveW = containerWidth - 2 * padding + gap
    const effectiveH = containerHeight - 2 * padding + gap
    const numHorizontal = Math.floor(effectiveW / (elementWidth + gap))
    const numVertical = Math.floor(effectiveH / (elementHeight + gap))
    return numHorizontal * (numVertical + (numVertical * 1) / 2)
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  return (
    <Container>
      <SectionFixed>
        {showSearch && (
          <div
            className="flex justify-between items-center"
            style={{
              padding: '10px 18px'
            }}
          >
            <AlphabetSearch
              alphabetKey={alphabetKey}
              setAlphabetKey={setAlphabetKey}
              meta={meta}
            />
            <div className="flex gap-2 items-center">
              {selectedPeople.length > 0 && (
                <>
                  <Button2
                    secondary
                    onClick={() => {
                      setSelectedPeople([])
                      setSelectedPeopleObj([])
                    }}
                  >
                    {/* <MergeIcon className="size-4 " /> */}
                    Unselect all
                  </Button2>
                  <Button2 secondary onClick={() => handleMerge()}>
                    <MergeIcon className="size-4 " />
                    Merge
                  </Button2>
                  <Button2 secondary onClick={() => setShowTagDrawer(true)}>
                    <Label className="size-4 " />
                    Tag People
                  </Button2>
                </>
              )}

              <TagFilter
                showButton
                showTags={false}
                tags={tagsOptions}
                disabled={loading}
                selectedTags={selectedTags}
                onChange={handleTagsValueChange}
                filterIcon={
                  _.isEmpty(selectedTags) && (
                    <Button2 secondary>
                      <FilterListIcon className="size-4 " />
                      Filter
                    </Button2>
                  )
                }
                clearFilter={
                  !_.isEmpty(selectedTags) && (
                    <Button2 secondary onClick={() => refresh()}>
                      <CrossIcon className="size-4 text-grey-500 " />
                      clear filter
                    </Button2>
                  )
                }
              />

              <SearchNew
                value={searchValue}
                onChange={(value) => handleSearch(value)}
                onClear={() => {
                  handleSearch('')
                  setLoading(true)

                  fetchData(1, '')
                }}
                onEnter={(value) => {
                  handleSearch(value)
                  handleSearchEnter({ keyCode: 13 })
                }}
              />
            </div>
          </div>
        )}
      </SectionFixed>
      <Box
        ref={ref}
        sx={{
          height: '100% ',
          overflow: 'hidden',
          width: '100%',
          padding: '0 10px',
          boxSizing: 'border-box'
        }}
        // onScroll={(e) => {
        //   infiniteScroll()
        // }}
        id="scrollableDiv"
      >
        <TagFilter
          showButton={false}
          showTags
          tags={tagsOptions}
          disabled={loading || isUserViewer}
          selectedTags={selectedTags}
          onChange={handleTagsValueChange}
        />

        {!masterResumeId && (
          <PeopleTable
            isSuperUser={isSuperUser}
            handleMasterResumeNameUpdate={handleMasterResumeNameUpdate}
            fetchPage={(page) => {
              setPage(page)
              setLoading(true)
            }}
            pageLoading={pageLoading}
            hasNext={hasMoreData}
            loading={loading}
            s3Obj={s3Obj}
            setShowTagDrawer={(id) => {
              setSelectedPeople([id])
              setShowTagDrawer(true)
            }}
            selectedPeopleObj={selectedPeopleObj}
            setSelectedPeopleObj={setSelectedPeopleObj}
            setSelectedPeople={setSelectedPeople}
            isMerge={isMerge}
            peopleList={peopleData ?? []}
            selectedPeople={selectedPeople}
            displayPeople={peopleData}
            setResumeOpen={(val) => {
              setResumeOpen(val)
              setCurrentResumeIndex(0)
            }}
            meta={meta}
          />
        )}

        {/* <ResumeResult
          s3Obj={s3Obj}
          people={isIndividualResume || peopleList}
          setSelectedScreenshots={setSelectedScreenshots}
          setIsResumeView={setIsResumeView}
          isMerge={isMerge}
          setSelectedPeople={setSelectedPeople}
          selectedPeople={selectedPeople}
          notesList={notesList}
          setNotesList={setNotesList}
          tagState={tagState}
          currentMasterPeopleIndex={currentMasterPeopleIndex}
          currentResumeIndex={currentResumeIndex}
          isIndividualResumeView={isIndividualResume}
          setIsIndividualResume={setIsIndividualResume}
          refresh={refresh}
        /> */}
      </Box>

      {showMergeModal && (
        <MergeModal
          isOpen={showMergeModal}
          s3Obj={s3Obj}
          onMerge={() => {
            refresh()
            setShowMergeModal(false)
            setSelectedPeople([])
            setSelectedPeopleObj([])
          }}
          selectedPeopleList={selectedPeopleObj}
          onClose={() => setShowMergeModal(false)}
        />
      )}
      {selectedScreenshots.length > 0 && (
        <ImageGallery
          images={selectedScreenshots}
          onClose={() => setSelectedScreenshots([])}
        />
      )}
      {renderTagDrawer()}
    </Container>
  )
}

export default PeopleResume
